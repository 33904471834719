<template>
  <span class="d-flex text-secondary">
    <div class="spinner-border spinner-border-sm m-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mt-1 small">{{text}}</div>
  </span>
</template>

<script>
  export default {
    name: "PleaseWaitComponent",
    props: {
      text: {
        type: String,
        default: 'Please wait'
      }
    },
  };
</script>

<style scoped>
</style>