<template>
    <div class="container bg-white p-3">
        <div class="mb-4 bg-light s005" style="border-top: 6px solid #0085a1;">
            <span class="float-end backBtn">
                <router-link class="backBtn" to="/"><i class="bi bi-house-fill"></i></router-link>
            </span>
            <div class="container-fluid py-5 " style="background: rgba(0, 0, 0, 0.438);">

                <div class="ms-4">
                    <h1 class="display-5 fw-bold">allmedstores</h1>
                    <h4 class="fw-bold" style="color: #9de3f1;">Pharmacy Digital Kit</h4>
                    <p class="col-md-8 fs-5">We help the pharmacies to turn their physical pharmacy
                        store
                        into a digital store where the public can find any drug they are looking for irrespective of
                        their location.</p>

                    <a class="btn fw-bold" href="https://store.matsmartshop.com/register">Sign Up Now</a>
                    <a class="btn fw-bold ms-2" style="background-color: #9de3f1; width: 120px;"
                        href="https://store.matsmartshop.com/login">Log In</a>
                </div>
            </div>
        </div>

        <div class="row shadow" style="border: 1px #bfdbe0 solid; margin: 20px 0px;">
            <div class="col-md-7 border-right">
                <div class="card">
                    <div class="row justify-content-center mt-3">
                        <span class="heading-middle">HOW CUSTOMERS GET IN:</span>
                        <hr class="line">
                    </div>
                    <div class="card-body">
                        <img style="width: 100%;" src="@/assets/allmedFlyer.jpg" alt="allmedstores.com">
                    </div>
                </div>



            </div>
            <div class="col-md-5">
                <div class="mb-3">
                    <div class="row justify-content-center m-3">
                        <span class="heading-middle">WHAT YOU GET:</span>
                        <hr class="line">
                    </div>
                    <div class="text">
                        <div class="accordion accordion-flush" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button headTopic" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi isMobile bi-folder-symlink"></i>&nbsp;Online Drugs Directory
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            <strong>Online Drugs Digital Directory:</strong> A pool of drugs available
                                            from pharmacies across the country. The general public are able to identify
                                            your store as having a drug they seek irrespective of where they are, People
                                            within your immediate environment as well as digital global community. They
                                            will be able to identify drugs you have available and directly approach you
                                            either online or offline, and also procure drugs from you that aren't
                                            available within their environs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed headTopic" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <i class="bi isMobile bi-cart3"></i>&nbsp;Online Store
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            <strong>Online Digital Store:</strong> Have your own digital store where
                                            customers can place order for over the counter drugs. It helps you as a
                                            pharmacist to expand your business horizons and impact sales positively. It
                                            also helps you to contribute more effectively to good healthcare digitally
                                            as your store is linked to the online directory. The main idea behind having
                                            an online store revolves around offering a wide range of medicines,
                                            delivering the purchased items on time to patients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed headTopic" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        <i class="bi isMobile bi-reception-3"></i>&nbsp;Mobility
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p> <strong>Manage your store from anywhere:</strong>
                                            Empowers you to digitalize your physical store from anywhere, respondto
                                            customers orders and enquiries via chat or email automatically from the app.
                                            You can also recommend drugs from the app.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed headTopic" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        <i class="bi isMobile bi-pie-chart-fill"></i>&nbsp;Income & expenses
                                        Management
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Track sales, expenses, inventory, customer and
                                            vendor balances,
                                            and generate profit & loss report. Understanding your pharmacy’s
                                            financials enables you
                                            to make better business decisions.
                                            When you’re aware of the amount of money going in and coming out of
                                            your business, you
                                            have a better idea of your pharmacy’s overall financial health. Now
                                            you can keep track
                                            of every sale, the price of every drug and equipment sold, and the
                                            profit made. You can
                                            now easily analyze your business prospect and the flow of cash, and
                                            get the information
                                            you need to optimize the process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="row justify-content-center mt-5">
                    <span class="heading-middle">OTHER BENEFITS:</span>
                    <hr class="line">
                </div>
                <div class="text">
                    <div class="accordion accordion-flush" id="accordionExample2">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed headTopic" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true"
                                    aria-controls="collapseFive">
                                    <i class="bi isMobile bi-bar-chart-fill"></i>&nbsp;Predictive Analytic
                                    Stock Management
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample2">
                                <div class="accordion-body">
                                    <p>We will share with you predictive analytics as hown by visitors search and
                                        behaviors on our platform using keywords, frequency of search, pattern and other
                                        A.I. This can help you in deciding what drugs to stock and which to sell off.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button collapsed headTopic" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                                    aria-controls="collapseSix">
                                    <i class="bi isMobile bi-people-fill"></i>&nbsp;Marketing
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample2">
                                <div class="accordion-body">
                                    <p>Being part of the AllMedStores Platformshowcases you and your store to the
                                        digital world. Our various digital marketing tactics will help in acquiring
                                        customers who are yet to hear of you.
                                        Another marketing strategy we have in place is the Affiliate marketing whereby
                                        people can recommend your store to new customers or doctors recommend your store
                                        to their patients using your link and at the same time you can reward them with
                                        points.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSeven">
                                <button class="accordion-button collapsed headTopic" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false"
                                    aria-controls="collapseSeven">
                                    <i class="bi isMobile bi-people"></i>&nbsp;Discreet services
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample2">
                                <div class="accordion-body">
                                    <p>Some people may feel more comfortable purchasing their treatment online, or
                                        simply want to discuss with a professional about prescriptions and health either
                                        online or over the phone, rather than in person. This means that Allmedstores
                                        can attract these type of consumers to your store and help them purchase
                                        potentially 'embarrassing' products in a discreet manner, and also connect
                                        directly with you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>

            </div>
        </div>
        <div class="row p-3 shadow" style="border: 1px #bfdbe0 solid; margin: 20px 0px;">
            <div class="col-md-7">
                <div class="card">
                    <div class="card-body">
                        <img style="width: 100%;" src="@/assets/allmedFlow.jpg" alt="allmedstores.com: enrollment">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card text-black" style="background: #f9f9fa">
                    <div class="card-body">
                        <span class="heading-middle">HOW TO SUBSCRIBE:</span>
                        <hr>
                        <ol class="fst-italic">
                            <li><span>Register your store and fill neccesary details</span></li>
                            <li><span>Confirm your email address for your store to be rendered
                                    <b>active</b></span>
                            </li>
                            <li><span>A link will also be sent to your mail which must be clicked on while
                                    you
                                    are in your physical store for your location and it’s coordinates to be
                                    automatically
                                    captured, this enables your store to be identified with any nearby search.</span>
                            </li>
                            <li><span>
                                    We have developed an integration with the quickbooks add-on
                                    (web connector) to handshake with your quickbooks POS and send us any data we need.
                                    If you’re yet to get a POS system you’ll be required to download the Matsmartshop
                                    desktop,
                                    install it, bring in your inventories, sending out invoices/receipts,
                                    capturing purchases from the app.
                                </span>
                            </li>
                            <li><span>Indicate your opening & closing hours/days so that any
                                    search results that hits your store will show if you are open or not.</span></li>
                            <li>
                                <span>Once the setup is done your store is <b>live</b>, all you need to indicate
                                    here
                                    are drugs that’ll be available over the counter and the ones which will be available
                                    in
                                    the online store.
                                </span>
                            </li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center my-4">

            <span class="text-center fs-3">For More Info:</span> <br>
            <span class="text-center mb-5">
                <b>Contact us:</b> <br>
                <i class="bi bi-whatsapp"></i> 07017021180 |
                <i class="bi bi-phone"></i> 09092942048
            </span>


            <span class="text-center fw-lighter fs-3">You Own a Pharmacy Store?.</span>
            <div class="text-center">
                <a class="btn btn-lg fw-bold" href="https://store.matsmartshop.com/register">Sign Up</a>
                <a class="btn btn-lg fw-bold ms-2" style="background-color: #9de3f1;"
                    href="https://store.matsmartshop.com/login">Log In</a>
            </div>
        </div>
    </div>
    <footerComponent />
</template>

<script>
import footerComponent from "@/components/footerComponent.vue";
import { onMounted, inject } from 'vue'
export default {
    components: {
        footerComponent
    },
    setup() {
        const codeStore = inject('codeStore')
        const u_methods = codeStore.methods
        onMounted(() => { u_methods.checkDateForPWAupdate() })
    }
}

</script>

<style scoped>
.backBtn {
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    margin: 14px;
    color: #fff;
    text-decoration: none;
}

.card {
    border: none;
    /* height: 100%; */
    padding: 0px 10px;
}


/* .card:hover {
        transform: scale(1.02);
    } */

.headTopic {
    font-size: 18px;
    font-weight: bolder;
    font-family: 'Open Sans' !important;
}

@media screen and (max-width: 900px) {
    .headTopic {
        font-size: 13px;
    }
}

.heading-middle {
    font-size: 25px;
    text-align: center;
    font-weight: bolder;
}

.line {
    background-color: #0085a1;
    margin: 0px 0px 0px 0px;
    height: 5px;
    width: 50px;
}

.accordion-button:hover,
.accordion-button:focus,
.accordion-button:active {
    color: #0085a1;
    background-color: none;
    box-shadow: none;
}

.btn {
    background-color: #edad18;
    color: #000;
    /* width: 150px; */
}

.btn:focus,
.btn:active {
    color: #0085a1;
    background-color: none;
    box-shadow: none;
}

.btn:hover {
    background-color: #f1ca6e;
}

ol>li {
    font-weight: bold;
    margin-top: 20px;
}

li>span {
    font-weight: normal;
}

ol {
    list-style-type: none;
    counter-reset: elementcounter;
    padding-left: 0;
}

li:before {
    content: "Step "counter(elementcounter) ". ";
    counter-increment: elementcounter;
    font-weight: bold;
}
</style>