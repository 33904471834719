<template>
  <div class="card border-1 shadow-sm col-md-12 hover">
    <div class="card-body">
      <h6 class="card-title">
        <b>Contact Us @:</b>
      </h6>
      <div class="d-grid">
        <!-- <span class="nearList">
          <i class="bi bi-telephone"></i> &nbsp;
          <a href="tel:+2348175420054">+234-8175420054</a> |
          <a href="tel:+2349092942048">+234-9092942048</a>
        </span> -->
        <span class="nearList">
          info@matsmartshop.com
        </span>
        <!-- <span class="nearList">
          <i class="bi bi-geo-alt"></i>&nbsp;248, Ikorodu Road, Obanikoro Bus
          Stop, Lagos, Nigeria.
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ContactUsComponent",
  };
</script>

<style scoped src="@/assets/css/searchResults.css"></style>
<style scoped>
  .nearList {
    padding: 7px;
    /* border-bottom: 1px solid #f5f5f5; */
    padding-bottom: 20px;
  }

  .card-title {
    background-color: #f5f5f5;
    color: #000;
    padding: 10px;
    border-radius: 5px;
  }
</style>