<template>
  <router-view></router-view>
</template>


<script>
  import { provide } from 'vue'
  import codeStore from '@/codeStore'
  export default {
    setup() {
      provide('codeStore', codeStore)
    }
  }
</script>


<style>
  /* .moveInUp-enter-active {
    animation: fadeIn 0.5s ease-in;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  } */
</style>