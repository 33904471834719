<template>
  <PageNotExist showHomeButton="yes" />
</template>

<script>
  import PageNotExist from '@/components/PageNotExist.vue'
  export default {
    name: "PageNotFound",
    components: {
      PageNotExist
    },
  };
</script>