<template>
  <div>
    <nav v-if="navigateToHome == 'true'" class="navbar">
      <router-link class="navbar-brand" to="/"><img src="@/assets/icon.png" width="30" alt="" /> &nbsp;<span
          class="text-white fw-bold">allmedstores</span>
      </router-link>
      <span @click="u_values.showSearchBar = !u_values.showSearchBar" style="cursor: pointer"
        class=" mx-3 text-white fw-bold isMobile">
        <i class="bi bi-search"></i>
        <input type="text" class="form-control form-control-sm upStackInput" placeholder="search more..." disabled>
      </span>
    </nav>
    <nav v-else class=" navbar">
      <router-link @click.prevent class="navbar-brand" to="#"><img src="@/assets/icon.png" width="30" alt="" />
        &nbsp;<span class="text-white fw-bold">allmedstores</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
  import { inject } from "vue";
  export default {
    name: "NavBarNameComponent",
    props: { navigateToHome: String },
    setup() {
      const codeStore = inject('codeStore')
      const u_values = codeStore.values
      return { u_values };
    },
  };
</script>

<style scoped>
  .navbar {
    padding: 2px;
    margin: 0px;
    background-color: #0085a1;
  }

  .navbar-brand:hover {
    color: #edad18;
  }

  .upStackInput {
    border-radius: 12px;
    border: none;
    background-color: #fff !important;
    cursor: pointer;
    /* width: 200px; */
  }

  .isMobile i {
    position: absolute;
    color: #ccc;
    /* left: 30; */
    margin-top: 7px;
    margin-left: 10px;
  }

  input::placeholder {
    padding-left: 24px !important;
    color: #ccc;
  }

  .isMobile {
    width: 100%;
    max-width: 200px;
  }
</style>