<template>
    <div>
        <div class="stick-top">
            <NavBarNameComponent navigateToHome="false" />
        </div>
        <div class="container">
            <div v-if="screen.loading" class="row justify-content-center">
                <div class="col-12 col-xl-6" style="margin-top: 100px;">
                    <h4 class="text-center text-muted">Loading Store.. <b>please wait</b></h4>
                </div>
            </div>
            <div v-else class="row justify-content-center">
                <div v-if="screen.error || !screen.data">
                    <div class="col-12 col-xl-6" style="margin-top: 100px;">
                        <h5 class="text-center text-danger text-muted">Store not found</h5>
                    </div>
                </div>
                <div v-else class="col-12 col-xl-6" style="margin-top: 70px;">
                    <div class="card shadow">
                        <div class="card-header py-3">
                            <div>
                                <span class="text-muted">
                                    <i class="bi bi-telegram"></i> Shared store
                                </span>
                                <span v-show="screen.data.DeliveryAvaliable" class="float-end">
                                    <small class="fst-italic">
                                        <span class="deliveryBadge rounded-pill p-1 px-2"><i
                                                class="bi bi-check-circle"></i>
                                            Delivery available
                                        </span>&nbsp;
                                    </small>

                                </span>
                            </div>

                        </div>
                        <div class="card-body">
                            <h2 class="post-title fs-4 fw-bold text-capitalize"> {{ screen.data.StoreName }}</h2>
                            <p> <small>{{ screen.data.Address }}</small></p>
                            <hr>
                            <p>
                                <span v-for="(drug, index) in screen.data.medStoresItems" :key="index"
                                    class="text-lowercase badge text-dark">
                                    <mark class="p-1 fw-normal small fw-bold">{{ drug.ItemName }}</mark>
                                    <button @click.prevent class="mx-1 border-0" v-show="drug.IsPrescription"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="prescription required">
                                        <i class="bi bi-exclamation-circle-fill"></i>
                                    </button>
                                    <span v-show="drug.Price" class="fw-bold">
                                        - &#8358;{{ u_methods.numberWithCommas(drug.Price) }} </span>&nbsp;


                                </span>
                            </p>
                            <p class="card-text float-end my-4">
                                <a v-show="screen.data.StoreWhatappPhone"
                                    :href="'https://api.whatsapp.com/send?phone=' + screen.data.StoreWhatappPhone + '&text=Hello, ' + screen.data.StoreName"
                                    class="whatsappBtn small fw-bold me-3">
                                    <small><i class="bi small bi-whatsapp"></i> whatsapp</small>
                                </a>
                                <a :href="'tel:' + screen.data.PhoneNumber" class="callBtn small fw-bold">
                                    <small><i class="bi small bi-telephone-fill small"></i> call now</small>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="mt-5 text-center fw-bold small">
                        Search this item around your location?<span @click="searchDrug" class="actionBtn small">Click
                            here</span>

                    </div>
                    <div class="text-center fst-italic"><small> <b>Note:</b> Allow LOCATION access on
                            prompt.</small>
                    </div>
                </div>
            </div>

        </div>

        <!-- <footerComponent /> -->
    </div>
</template>



<script>
import { onMounted, reactive, inject } from 'vue'
import NavBarNameComponent from "@/components/NavBarNameComponent.vue";
import footerComponent from "@/components/footerComponent.vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
export default {
    components: { NavBarNameComponent, footerComponent },
    setup(props) {
        const codeStore = inject('codeStore')
        const baseURL = codeStore.constants.baseURL
        const u_methods = codeStore.methods
        const route = useRoute();
        const router = useRouter();

        const screen = reactive({
            loading: false,
            error: false,
            data: {}
        })


        onMounted(() => {
            loadStore()
        });

        async function loadStore() {
            screen.loading = true
            screen.error = false
            var objectToSend = {
                DrugName: route.query.drg,
                Latitude: route.query.lt,
                Longitude: route.query.lg,
            };

            const APIstring = baseURL + "MedStoresApi/GetAllStoreDetailsByLocation";
            // ;

            try {
                var { data } = await axios.post(APIstring, objectToSend, {
                    timeout: 40000,
                });
                const response = data.MedicalStoreResponesStatus;
                const result = data.MedicalStoreResult;
                // console.log(data)
                // console.log(objectToSend);
                switch (response) {
                    case 200:
                        screen.loading = false
                        screen.error = false
                        screen.data = result.find(x => x.StoreId == route.query.str)
                        // console.log(screen.data);
                        break;
                    case 50:
                        expandSearch(objectToSend)
                        break;
                    default:
                        screen.loading = false
                        screen.error = true
                        break;
                }
            } catch (error) {
                screen.loading = false
                screen.error = true
                console.log(error);
            }
        }

        async function expandSearch(objectToSend) {
            const APIstring = baseURL + "MedStoresApi/GetAllStoreAreaByLocation"
            var { data } = await axios.post(APIstring, objectToSend, {
                timeout: 40000,
            });
            const response = data.MedicalStoreResult
            let result1 = response.NearByAreas.find(x => x.StoreId == route.query.str)
            let result2 = response.ByAreas.find(x => x.StoreId == route.query.str)
            screen.data = result1 == undefined ? result2 : result1
            screen.loading = false
            screen.error = false
            // console.log(result)
        }

        function searchDrug() {
            router.push({
                name: "results",
                params: {
                    toSearch: route.query.drg
                },
            });
        }

        return { route, screen, searchDrug, u_methods }
    }
}
</script>

<style scoped src="@/assets/css/searchResults.css">
</style>
<style scoped>
.callBtn {
    min-width: 1.8em;
    padding: 0.2em 1em 0.5em 1em !important;
    margin-left: 0.1em;
    background-color: #c8dfe4;
    color: #0085A1 !important;
    cursor: pointer;
    border-radius: 5px;
}

.callBtn:hover {
    background-color: #a5cfd8;
}

.whatsappBtn {
    min-width: 1.8em;
    padding: 0.2em 1em 0.5em 1em !important;
    margin-right: 0.5em;
    background-color: #c0f3c0;
    color: #1dbe1d !important;
    cursor: pointer;
    border-radius: 5px;
}

.whatsappBtn:hover {
    background-color: #abe6ab;
}



.actionBtn {
    min-width: 1.8em;
    padding: 0em 0.21em !important;
    margin-left: 0.21em;
    /* background-color: #d4f1f5; */
    color: #0085a1 !important;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.actionBtn:hover {
    background-color: #55def7;
}

.card {
    border-bottom: 2px solid #edad18;
}

.deliveryBadge {
    font-size: 12px;
    background-color: #f1ead6;
    /* background-color: #fce8b6; */
    border-radius: 20px;
    /* font-weight: bold; */
}

.badge {
    margin-right: 14px;
    margin-bottom: 6px;
    font-size: 13px;
    background-color: #f3f1f1;
    padding: 10px 8px;
    /* border-radius: 20px; */
}
</style>