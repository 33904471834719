<template>
  <div class=" px-lg-5">
    <div class="container col-md-6">
      <div class="text-danger bg-light my-5 mx-2 p-5 row col-md-12">
        <div class="col-md-12 text-center mt-4" style="font-size: 5rem">
          <i class="bi bi-exclamation-circle"></i>
        </div>
        <div class="col-md-12 text-center mt-4">
          Location Access Denied
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LocationDeniedComponent",
  };
</script>

<style lang="sass" scoped>
</style>