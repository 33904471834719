<template>
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">
        <h4 :class="{ isMobile: isMobile }">
          <img src="@/assets/icon.png" alt="allmedstores" />
          &nbsp;<span class="text-white fw-bold isMobile">allmedstores</span>
          <!-- <a style="text-decoration: none; color: #ddff1f" ref="homeBtn" href="/">allMedStores.com</a> -->
        </h4>
      </div>
      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <router-link class="nav-link scrollto fw-bold" to="/pharmacists">
              <span :class="{ isMobile: isMobile }">
                I'm a pharmacist <i class="bi bi-arrow-right"></i>
              </span>
            </router-link>
            <!-- <button @click="openModal" class="btn btn-link text-white nav-link scrollto">
              Test
            </button> -->
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <div class="s006" id="mainOps">
    <SearchBar @toggleLogo="hideLogoOnInput" />
  </div>

  <span class="fixed-bottom" :class="{ isMobile: isMobile }">
    <div class="container-fluid justify-content-end sharePanel">
      <a @click.prevent="u_methods.shareSite" href=""><span> <i class="bi bi-share-fill"></i></span></a>
    </div>
  </span>

  <nav class="fixed-bottom" style="font-family: 'Open Sans', sans-serif">
    <div class="container-fluid d-flex justify-content-center py-2 copyText">
      <small class="text-white">COPYRIGHT &copy; 2022. POWERED BY
        <a class="text-white text-decoration-none" href="https://matsmartshop.com/">MATSMARTSHOP</a></small>
    </div>
  </nav>
</template>


<script>
  // @ is an alias to /src
  import SearchBar from "@/components/SearchBar.vue";
  import { ref, inject } from "vue";
  import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";

  export default {
    name: "Home",
    components: {
      SearchBar,
    },
    setup() {
      const codeStore = inject("codeStore");
      const u_methods = codeStore.methods;

      const isMobile = ref(false);
      const hideLogoOnInput = (val) => {
        isMobile.value = val == true ? true : false;
      };

      return {
        isMobile,
        hideLogoOnInput,
        u_methods,
      };
    },
  };
</script>

<style scoped>
  .sharePanel {
    margin-bottom: 40px;
    padding-right: 50px;
    display: flex;
    z-index: 999;
    position: relative;
  }

  @media screen and (max-width: 767px) {
    .sharePanel {
      padding-right: 20px;
    }

    .copyText {
      font-size: 13px;
    }
  }

  .sharePanel>a {
    color: #fff;
    background: rgba(0, 0, 0, 0.39);
    /* background: #0085a1; */
    padding: 17px;
    border-radius: 35%;

  }
</style>