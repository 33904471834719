<template>
  <div class="row px-4 px-lg-5">
    <div class="container col-md-6">
      <div class="alert alert-ligh mt-5 col-md-12">
        <h5><b>Something Went Wrong!</b></h5>
        <p><i>We are experiencing issues processing your search results.</i></p>
        <p><b>Try:</b></p>
        <ul>
          <li>checking your internet connection,</li>
          <li>
            refreshing this page or check later, we may be experiencing service delay.
          </li>
        </ul>

        <p class="mt-5 fs-5 bg-light p-2 text-center">
          <router-link style="text-decoration: none" to="/">try again</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LocationDeniedComponent",
  };
</script>

<style lang="sass" scoped>
</style>