import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/css/main.css'
import 'pretty-checkbox';

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import loader from "vue-ui-preloader";

const allMedApp = createApp(App);
allMedApp.use(router)
allMedApp.use(Toast)
allMedApp.use(loader)
allMedApp.mount('#app')