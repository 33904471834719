<template>
  <div class="footer container-fluid">
    <footer class="d-flex flex-wrap justify-content-center align-items-center">
      <ul class="nav col-md-12 justify-content-center list-unstyled d-flex">
        <span class="text-muted">&copy; 2022 MATSMARTSHOP</span>
        <li class="ms-3">
          <a class="text-muted" @click.prevent href=""><i class="bi bi-twitter"></i></a>
        </li>
        <li class="ms-3">
          <a class="text-muted" @click.prevent="socialIG" href=""><i class="bi bi-instagram"></i></a>
        </li>
        <li class="ms-3">
          <a class="text-muted" @click.prevent  href=""><i class="bi bi-facebook"></i></a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
  name: "footerComponent",
  setup(props) {
    function socialIG(){
      window.location = 'https://www.instagram.com/allmedstores/'
    }

    // function socialFB(){
    //   window.location = '#'
    // }

    return {
      socialIG,
      // socialFB
    }
  }
};
</script>

<style scoped>
.footer {
  background: rgb(245, 243, 243);
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
  overflow: hidden;
  padding: 45px;
  margin-top: 140px;
  font-weight: bold;
  font-size: 15px;
}
</style>