import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotExist from '../views/PageNotExist.vue'
import SearchResults from '../views/SearchResults.vue'
import sharedStore from '../views/sharedStore.vue'
import InfoPage from '../views/pharmacists.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: ['/home', '/search']
  },
  {
    path: '/search/=:toSearch',
    name: 'results',
    component: SearchResults,
    props: true
  },

  {
    path: '/pharmacists',
    name: 'Pharmacists',
    component: InfoPage
  },

  {
    path: '/testPage000',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestPage.vue')
  },

  {
    path: '/shareStore',
    // path: '/shareStore/nm=:storeName&ad=:storeAddress&ph=:storePhone&dg=:drugName&dv=:delivery&wh=:whatsapp',
    name: 'none',
    component: sharedStore,
    // props: true,
    props: route => ({ query: route.query }),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: PageNotExist
  },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.resolve({
//   name: 'not-found',
//   params: { pathMatch: ['not', 'found'] },
// }).href

export default router
