<template>
    <div style="margin-top: 20px;" class="py-5 container bg-white shadow-sm">
        <div class="card-body">
            <div class="text-center">
                <img src="@/assets/3181324.png" width="40" alt="" />
            </div>
            <h3 class="card-title text-center text-muted fw-bolder">
                No search results for <b> <mark class="p-0">{{ thisItemSearched }}</mark> </b>
            </h3>



            <div class="mt-4">
                <!-- <div v-if="(u_values.nearByStoresArray.length +  u_values.otherStoresArray.length)">
                    <p class="card-text text-center fs-5 fst-italic"> We couldn't find it within your area. <br>
                        We found other <b>near-by stores</b> where this item can be found.
                    </p>
                </div> -->


                <div
                    v-if="u_values.nearbySearchIsComplete && !(u_values.nearByStoresArray.length +  u_values.otherStoresArray.length)">
                    <div class="col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-md-6 row align-content-center">
                                <p class="card-text fs-6 fst-italic">
                                <h5 class="fw-bold">We couldn't find it anywhere.</h5>
                                We can notify you when this item is available if you provide us with your email.
                                </p>
                                <!-- <p class="card-text fs-6 fst-italic"> You may also try expanding your search using the
                                    <b>expand search</b>
                                    option.
                                </p> -->
                            </div>
                            <div class="col-md-6 py-4 shadow mt-2">
                                <form @submit.prevent="sendMailMethod" class="row g-3 justify-content-center">
                                    <div class="px-4">
                                        <input class="form-control" v-model.trim="userEmail" type="text"
                                            placeholder="enter email" />
                                    </div>
                                    <div class="px-4">
                                        <button type="submit" class="btn btn-secondary w-100">SUBMIT&nbsp;<i
                                                class="bi bi-check-circle-fill"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





        </div>
    </div>
</template>

<script>
    import { ref, computed, onMounted, inject } from "vue";
    export default {
        name: "NoSearchResultsComponent",
        props: {
            itemSearched: String,
        },
        setup(props) {
            const codeStore = inject('codeStore')
            const u_values = codeStore.values


            const userEmail = ref(null);
            const thisItemSearched = ref(props.itemSearched);
            const sendMailMethod = () => {
                console.log(userEmail.value, props.itemSearched);
            };

            return {
                userEmail: userEmail,
                sendMailMethod: sendMailMethod,
                thisItemSearched: thisItemSearched,
                u_values
            };
        },
    };
</script>
<style scoped>
    .form-control:focus {
        box-shadow: none;
    }
</style>