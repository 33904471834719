<template>
    <form @submit.prevent="searchNameOnSubmit">
        <fieldset>
            <legend>
                Find <span style="color: #ff0">{{ randomText }}</span> from pharmacy
                near you.
            </legend>
            <div class="inner-form">
                <div class="input-field">
                    <span class="btn-search" @click="searchNameOnSubmit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                            </path>
                        </svg>
                    </span>
                    <input :class="{ userTyping: userIsTyping, boxIsIdle: boxIsIdle }" @keyup="listenToInput"
                        v-model="nameToSearch" type="text" placeholder="eg: Paracetamol" />
                </div>
            </div>
            <div v-show="suggestionsList.length" class="suggestion-wrap overflow-auto" style="max-height: 250px">
                <span v-for="(suggests, index) in suggestionsList" :key="index">
                    <i @click.prevent="searchName(suggests)" class="btn btn-link suggsMain">{{ suggests }}</i>
                </span>
            </div>
        </fieldset>
    </form>
</template>

<script>
import { ref, computed, onMounted, reactive, toRefs, inject } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    name: "SearchBar",
    emits: ["toggleLogo"],
    setup(props, context) {
        //get common data
        const codeStore = inject("codeStore");
        const baseURL = codeStore.constants.baseURL;
        const u_methods = codeStore.methods;
        const u_values = codeStore.values;

        const router = useRouter();
        const route = useRoute();

        const searchBlock = reactive({
            nameToSearch: "",
            suggestionsList: [],
            suggestionsFromDB: [],
            userIsTyping: computed(() => {
                return searchBlock.suggestionsList.length ? true : false;
            }),
            boxIsIdle: computed(() => {
                return searchBlock.suggestionsList.length ? false : true;
            }),
            randomTextsArray: ["drugs", "cosmetics"],
            randomText: "products",
        });

        Array.prototype.random = function () {
            return this[Math.floor(Math.random() * this.length)];
        };

        const randomizeTexts = () => {
            setInterval(() => {
                searchBlock.randomText = searchBlock.randomTextsArray.random();
            }, 2000);
        };

        onMounted(() => {
            randomizeTexts();
            let savedSuggestions = JSON.parse(
                sessionStorage.getItem("allmedstoresSuggestions")
            );
            if (savedSuggestions == null) {
                loadSuggestionsFromDB();
            } else {
                u_values.suggestions = searchBlock.suggestionsFromDB = savedSuggestions;
                console.log("suggestions loaded from session");
            }
        });

        async function loadSuggestionsFromDB() {
            console.log("loading suggestions..");
            const headers = { "Content-Type": "application/json" };
            // const tempAPI = "https://jsonplaceholder.typicode.com/users";
            const SuggFetchAPI =
                baseURL + "MedStoresApi/GetDrugsSearchSuggestions?SuggestionName=Drugs";
            try {
                const response = await axios.get(SuggFetchAPI, headers);
                // console.log(response);
                if (response.data.MedicalStoreResponesStatus == 200) {
                    var list = response.data.MedicalStoreResult;
                    if (list.length) console.log("suggestions loaded");
                    const filteredList = list.map((x) => x.DrugsName);
                    filteredList.forEach((element) => {
                        if (searchBlock.suggestionsFromDB.indexOf(element) === -1) {
                            searchBlock.suggestionsFromDB.push(element);
                        }
                    });
                    u_values.suggestions = searchBlock.suggestionsFromDB;
                    sessionStorage.setItem(
                        "allmedstoresSuggestions",
                        JSON.stringify(searchBlock.suggestionsFromDB)
                    );
                } else {
                    console.log(
                        response.data.MedicalStoreResponesStatus,
                        "failed to load suggestions"
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }

        const listenToInput = (e) => {
            const enteredData = e.target.value;
            toggleLogo(enteredData);
            if (enteredData) {
                var tempArray = [];
                searchBlock.suggestionsFromDB.forEach((element) => {
                    var splittedArray = u_methods.splitWords(element);
                    splittedArray.forEach((splitted) => {
                        if (
                            splitted
                                .toLocaleLowerCase()
                                .startsWith(enteredData.toLocaleLowerCase())
                        ) {
                            tempArray.push(element.toLocaleLowerCase());
                        }
                    });
                });
                searchBlock.suggestionsList = tempArray;
            } else {
                searchBlock.suggestionsList = [];
            }
        };

        const toggleLogo = (enteredData) => {
            let val = enteredData ? true : false;
            context.emit("toggleLogo", val);
        };

        const searchNameOnSubmit = () => {
            var lengthOfWords = searchBlock.nameToSearch.length;
            if (lengthOfWords) {
                if (lengthOfWords < 3) {
                    const toast = useToast();
                    toast.info("Not less than 3 characters", {
                        timeout: 5000,
                        hideProgressBar: true,
                        position: "top-center",
                    });
                } else searchName(searchBlock.nameToSearch);
            }
        };

        function searchName(name) {
            router.push({
                name: "results",
                params: {
                    toSearch: name,
                },
            });
        }

        return {
            searchName: searchName,
            searchNameOnSubmit: searchNameOnSubmit,
            listenToInput: listenToInput,
            ...toRefs(searchBlock),
        };
    },
};
</script>
<style scoped>
.suggsMain {
    margin: 0px;
    padding: 0px 1px;
    text-decoration: none;
    /* border: none; */
    color: #000;
    font-size: 13px;
}

@media screen and (max-width: 767px) {
    .suggsMain {
        font-size: 12px;
    }
}

.suggestion-wrap::-webkit-scrollbar {
    width: 20px;
}

.suggestion-wrap::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 8px solid transparent;
    background-clip: content-box;
}

.suggestion-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}
</style>