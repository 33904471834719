<template>
    <div class="d-flex justify-content-center" style="margin: 20px">
        <div class="card border-0" style="width: 30rem; margin-top: 100px">
            <div class="card-body">
                <h1 class="card-title text-center" style="font-size: 6rem">
                    <i class="bi bi-exclamation-triangle"></i>
                </h1>
                <h3 class="card-title text-center text-danger">
                    <b>Page Not Found</b>
                </h3>
                <p class="card-text">
                    Looks like you've followed a broken link or entered a URL that doesn't exist on this site.
                </p>
                <p v-if="showHomeButton == 'yes'" class="d-flex justify-content-center">
                    <router-link class="btn btn-outline-primary" to="/">
                        Go Back
                    </router-link>
                </p>
            </div>
        </div>
    </div>

    <!-- include SeachBar Component -->
</template>

<script>
    export default {
        name: "PageNotExist",
        props: { showHomeButton: String },
    };
</script>